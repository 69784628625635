/********************** Breakpoints *********************/

// https://beta.ionicframework.com/docs/layout/grid
// these are for reference only and aren't used by app
$grid-xs: 100%;
$grid-sm: 540px; // min-width: 576
$grid-md: 720px; // min-width: 768
$grid-lg: 960px; // min-width: 992
$grid-xl: 1140px; // min-width: 1200
// used to show/hide at different screen sizes
$app-xs: 100%;
$app-sm: 480px;
$app-md: 768px;
$app-lg: 992px; //side-menu appears here
$app-xl: 1200px;
/********************** Screenbreaks *********************/

// by default things to be shown at specific sizes start hidden
.show-sm {
  display: none;
}

@media only screen and (max-width: $app-sm) {
  .hide-sm {
    display: none;
  }
  .show-sm {
    display: block;
  }
}

@media only screen and (max-width: $app-md) {
  .hide-md {
    display: none;
  }
}

@media only screen and (max-width: $app-lg) {
  .hide-lg {
    display: none;
  }
}

/********************** Misc *********************/

ion-menu.side-menu,
#sideMenu {
  max-width: 250px;
  --width: 250px;
  --width-small: 250px;
}

.menu-inner {
  max-width: 250px;
}

ion-content {
  --ion-background-color: var(--ion-color-light);
}
p > img,
a > img {
  display: block;
  margin-bottom: 1em;
}

.viewer-backdrop {
  background-color: rgb(0 0 0 / 70%);
}
