// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #ba363a;
  --ion-color-primary-rgb: 186, 54, 58;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #a43033;
  --ion-color-primary-tint: #c14a4e;
  --ion-color-secondary: #ed9b51;
  --ion-color-secondary-rgb: 237, 155, 81;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d18847;
  --ion-color-secondary-tint: #efa562;
  --ion-color-tertiary: #c9a5b1;
  --ion-color-tertiary-rgb: 201, 165, 177;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #b1919c;
  --ion-color-tertiary-tint: #ceaeb9;
  --ion-color-success: #78f5aa;
  --ion-color-success-rgb: 120, 245, 170;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #6ad896;
  --ion-color-success-tint: #86f6b3;
  --ion-color-warning: #ffe98e;
  --ion-color-warning-rgb: 255, 233, 142;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0cd7d;
  --ion-color-warning-tint: #ffeb99;
  --ion-color-danger: #f8a5a5;
  --ion-color-danger-rgb: 248, 165, 165;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #da9191;
  --ion-color-danger-tint: #f9aeae;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
